import { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { modalType } from 'constantStrings';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ConfirmationModalProps {
  modalShow: boolean;
  setModalShow: Dispatch<SetStateAction<boolean>>;
  handleConfirmation: () => void;
  type?: string;
  showHeader?: boolean;
  modalHeading?: string;
  modalBody: string;
  closeText?: string;
  confirmationText?: string;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    modalShow,
    setModalShow,
    handleConfirmation,
    type,
    showHeader = true,
    modalHeading,
    modalBody,
    closeText = 'Close',
    confirmationText = 'Confirm'
  } = props;

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {showHeader && (
          <Modal.Header closeButton>
            {modalHeading ? (
              <Modal.Title id="contained-modal-title-vcenter">
                {modalHeading}
              </Modal.Title>
            ) : (
              <></>
            )}
          </Modal.Header>
        )}

        <Modal.Body className="d-flex gap-3">
          {type === modalType.DANGER && (
            <FontAwesomeIcon icon={faTrashCan} className={`text-danger`} />
          )}
          <p>{modalBody}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="subtle-secondary"
            onClick={() => setModalShow(false)}
          >
            {closeText}
          </Button>
          <Button
            variant={type ? type.toLowerCase() : 'primary'}
            onClick={() => {
              handleConfirmation();
              setModalShow(false);
            }}
          >
            {confirmationText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
