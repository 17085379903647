export const Status = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  DANGER: 'DANGER'
};

export const modalType = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  WARNING: 'WARNING',
  DANGER: 'DANGER'
};
