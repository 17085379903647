import { createBrowserRouter, RouteObject } from 'react-router-dom';
import Error404 from 'pages/error/Error404';
import App from 'App';
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      MainRoutes,
      AuthRoutes,
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
