import * as Yup from 'yup';
export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),

  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
});

export const forgotEmailValidationSchema = Yup.object({
  to: Yup.string().required('Email is required').email('Invalid email address')
});
