import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  user: string | null;
  token: string | null;
}

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem('dhToken'),
  user: localStorage.getItem('dhUser'),
  token: localStorage.getItem('dhToken')
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogin(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    }
  }
});

export const { setLogin, setLogout } = auth.actions;
export default auth.reducer;
