import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import useAuth from 'hooks/useAuth';
import ResourcesTable, {
  resourcesTablecolumns
} from 'components/tables/ResourcesTable';
import useResources from 'hooks/useResources';

const ResourcesPage = () => {
  const { resources, fetchAllResources, error, resetError } = useResources();
  const { logout } = useAuth();

  useEffect(() => {
    if (!resources.length) {
      fetchAllResources().catch(() => {});
    }
  }, [resources]);

  useEffect(() => {
    if (error === 'Unauthenticated') {
      logout();
      resetError();
    }
  }, [error]);

  const table = useAdvanceTable({
    data: resources,
    columns: resourcesTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Resources</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search resources"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <ResourcesTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default ResourcesPage;
