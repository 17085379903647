/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactSelect from 'components/base/ReactSelect';
import { Form } from 'react-bootstrap';
import { ScopeOption } from 'types/scopes';

interface ScopesSelectProps {
  title?: string;
  options: ScopeOption[];
  onSelectChange: (values: number[]) => void;
}
const ScopesSelect = ({
  title,
  options,
  onSelectChange
}: ScopesSelectProps) => {
  const handleChange = (e: any) => {
    const list = e.map((val: ScopeOption) => val.value);
    onSelectChange(list);
  };
  return (
    <div>
      {title ? <Form.Label>{title}</Form.Label> : <></>}
      <ReactSelect
        options={options}
        isMulti
        placeholder="Select organizer..."
        onChange={handleChange}
      />
    </div>
  );
};

ScopesSelect.propTypes = {};

export default ScopesSelect;
