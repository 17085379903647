import axios from 'axios';
import { useEffect } from 'react';
import AuthService from 'services/AuthService';
import { useDispatch, useSelector } from 'store';
import { setLogin, setLogout } from 'store/reducers/auth';
import { ForgotPassword, LoginFormData, ResetPassword } from 'types/auth';

const setSession = ({
  token,
  user,
  expires_at
}: {
  token: string | null;
  user?: string | null;
  expires_at?: string | null;
}) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('dhToken', token);
    localStorage.setItem('dhUser', user || '');
    localStorage.setItem('expires_at', expires_at || '');
  } else {
    localStorage.removeItem('dhToken');
    localStorage.removeItem('dhUser');
    localStorage.removeItem('expires_at');
    delete axios.defaults.headers.common.Authorization;
  }
};

const useAuth = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector(state => state.auth);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const user = localStorage.getItem('dhUser') || '';
    const token = localStorage.getItem('dhToken') || '';
    const expires_at = localStorage.getItem('expires_at' || '');
    if (user && token && expires_at) {
      setSession({ user, token, expires_at });
    } else {
      setSession({ token: null });
    }
  };
  const login = (data: LoginFormData) => {
    return new Promise((resolve, reject) => {
      AuthService.login(data)
        .then(resp => {
          const user: string = data.email;
          const token: string = resp?.data?.access_token || null;
          const expires_at: string = resp?.data?.expires_at || null;

          setSession({ user, token, expires_at });
          dispatch(setLogin({ user, token }));

          resolve({ user, token });
        })
        .catch(err => {
          reject(err);
        });
    });
  };
  const logout = () => {
    return new Promise(() => {
      AuthService.logout()
        .then(() => {
          setSession({ token: null });
          dispatch(setLogout());

          // resolve();
        })
        .catch(() => {
          dispatch(setLogout());
        });
    });
  };
  const forgotPassword = (data: ForgotPassword) => {
    return new Promise((resolve, reject) => {
      AuthService.forgotPassword(data)
        .then(() => {})
        .catch(err => reject(err));
    });
  };
  const resetPassword = (data: ResetPassword) => {
    return new Promise((resolve, reject) => {
      AuthService.resetPassword(data)
        .then(() => {})
        .catch(err => reject(err));
    });
  };
  return {
    isAuthenticated,
    user,
    init,
    login,
    logout,
    forgotPassword,
    resetPassword
  };
};

export default useAuth;
