import axios from 'axios';
const resourcesEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/auth/resources`;

const fetchAllResources = () => {
  return axios.get(`${resourcesEndpoint}`);
};

const ResourcesService = {
  fetchAllResources
};

export default ResourcesService;
