import axios from 'axios';
import { ForgotPassword, LoginFormData, ResetPassword } from '../types/auth';
import HttpClient from './httpClient';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/auth`;

const login = (data: LoginFormData) => {
  const loginType = process.env.REACT_APP_LOGIN_TYPE || '';
  const client_id = process.env.REACT_APP_CLIENT_ID || '';
  const client_secret = process.env.REACT_APP_CLIENT_SECRET || '';

  return HttpClient.post(`${authEndpoint}/login`, {
    email: data.email,
    password: data.password,
    login_type: loginType,
    client_id: client_id,
    client_secret: client_secret
  });
};
const forgotPassword = (data: ForgotPassword) => {
  return axios.post(`${authEndpoint}/request-password-reset`, data);
};
const resetPassword = (data: ResetPassword) => {
  return axios.post(`${authEndpoint}/password-reset`, data);
};
const logout = () => {
  return axios.post(`${authEndpoint}/logout`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('dhToken') //the token is a variable which holds the token
    }
  });
};

const AuthService = {
  login,
  logout,
  forgotPassword,
  resetPassword
};
export default AuthService;
