import { CreateRolesData } from 'types/roles';
import axios from 'axios';
const rolesEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/auth/roles`;

const fetchAllRoles = () => {
  return axios.get(`${rolesEndpoint}`);
};
const createRoles = (data: CreateRolesData) => {
  return axios.post(`${rolesEndpoint}`, data);
};

const RolesService = {
  fetchAllRoles,
  createRoles
};

export default RolesService;
