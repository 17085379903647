import { MultipleSelectOption } from 'types/common';
import { Resource } from 'types/resources';
import { TenantWithLogoUrl } from 'types/tenant';

export const getMultipleOptionDropdownList = (options: Resource[]) => {
  const list = options?.length
    ? options.map(opt => {
        return { value: opt.id, label: opt.name } as MultipleSelectOption;
      })
    : [];
  return list;
};

export const updateCurrentTenantList = ({
  tenants,
  updatedTenantDetails
}: {
  tenants: TenantWithLogoUrl[];
  updatedTenantDetails: TenantWithLogoUrl;
}) => {
  const updatedTenantID = updatedTenantDetails?.id;
  const updatedTenants = tenants.map((tenantDetails: TenantWithLogoUrl) => {
    if (tenantDetails?.id === updatedTenantID) {
      return updatedTenantDetails;
    }
    return tenantDetails;
  });
  return updatedTenants;
};
