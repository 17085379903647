/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { TenantInputValues } from 'types/tenant';
import { getMultipleOptionDropdownList } from './arrayUtils';

export const getDataFromResponse = (resp: AxiosResponse<any, any>) => {
  const data = resp?.data?.data || resp?.data?.rows || [];
  return data;
};

export const getTenantFormValues = (data: any) => {
  const resourcesList = data?.resources
    ? getMultipleOptionDropdownList(data.resources) || []
    : [];
  const tenantData: TenantInputValues = {
    // id: data?.id,
    name: data.name,
    name_alt: data.name_alt,
    logo: data.logo,
    email: data.email,
    // created_at: data.created_at,
    // updated_at: data.updated_at,
    has_rate_limit: data?.policy?.has_rate_limit,
    rate_limit: data?.policy?.rate_limit,
    resources: resourcesList
  };

  return tenantData;
};
