import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <Container fluid className="px-0">
      <Outlet />
    </Container>
  );
};

AuthLayout.propTypes = {};

export default AuthLayout;
