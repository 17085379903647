import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import { formatDate, getLogoUrl } from 'helpers/stringUtils';
import { TenantWithLogoUrl } from 'types/tenant';

export const tenantsTablecolumns: ColumnDef<TenantWithLogoUrl>[] = [
  {
    accessorKey: 'id',
    header: 'Id',
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: '50px', maxWidth: '70px' },
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start text-body-tertiary'
      }
    }
  },
  {
    accessorKey: 'name',
    header: 'Tenant',
    cell: ({ row: { original } }) => {
      const { name, logo } = original;
      return (
        <Link to="#!" className=" d-flex align-items-center text-body">
          {logo ? <Avatar src={getLogoUrl(logo || '')} size="m" /> : <></>}
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },

  {
    accessorKey: 'name_alt',
    header: 'Alternative Name',
    cell: ({ row: { original } }) => {
      const { name_alt } = original;
      return (
        <Link to={`#!`} className="fw-bold text-body-emphasis">
          {name_alt}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'pe-3'
      }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link to={`mailto:${email}`} className="fw-semibold">
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'Created at',
    meta: {
      headerProps: {
        style: { width: '40%', minWidth: '200px' },
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center text-body-tertiary'
      }
    },
    cell: ({ row: { original } }) => {
      const { created_at } = original;
      return <p>{formatDate(created_at)}</p>;
    }
  }
];

const TenantsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default TenantsTable;
