import { useDispatch, useSelector } from 'store';
import { fetchScopes } from 'store/reducers/scopesSlice';

const useScopes = () => {
  const dispatch = useDispatch();
  const { scopes, error, scopesOptions } = useSelector(state => state.scopes);
  const fetchAllScopes = () => {
    return new Promise(() => {
      dispatch(fetchScopes());
    });
  };
  return {
    scopes,
    error,
    scopesOptions,
    fetchAllScopes
  };
};

export default useScopes;
