import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from 'components/base/Button';
import useAuth from 'hooks/useAuth';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const [navItems] = useState([
    // {
    //   label: 'Profile',
    //   icon: 'user'
    // },
    {
      label: 'Dashboard',
      icon: 'pie-chart',
      link: '/dashboard'
    }
    // {
    //   label: 'Posts & Activity',
    //   icon: 'lock'
    // },
    // {
    //   label: 'Settings & Privacy ',
    //   icon: 'settings'
    // },
    // {
    //   label: 'Help Center',
    //   icon: 'help-circle'
    // },
    // {
    //   label: 'Language',
    //   icon: 'globe'
    // }
  ]);
  const { logout } = useAuth();
  const handleSignOut = async () => {
    await logout();
  };
  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={'/images/Profile_avatar_placeholder.png'} size="xl" />
            <h6 className="text-body-emphasis">Pegotec</h6>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          <Nav className="nav flex-column my-3">
            {navItems.map(item => (
              <Nav.Item key={item.label}>
                <Nav.Link href={item.link || ''} className="px-3">
                  <FeatherIcon
                    icon={item.icon}
                    size={16}
                    className="me-2 text-body"
                  />
                  <span className="text-body-highlight">{item.label}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <hr />
          <div className="px-3">
            <Button
              onClick={handleSignOut}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#!">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
