/* eslint-disable @typescript-eslint/no-explicit-any */
import { updateCurrentTenantList } from 'helpers/arrayUtils';
import { objectToFormData } from 'helpers/formUtils';
import { getDataFromResponse } from 'helpers/objectUtils';
import TenantService from 'services/TenantService';
import { useDispatch, useSelector } from 'store';
import { setAllTenants, setError } from 'store/reducers/tenantSlice';
import { TenantWithLogoFile } from 'types/tenant';

const useTenant = () => {
  const dispatch = useDispatch();
  const { tenants, error } = useSelector(state => state.tenant);

  const getErrorMessage = (resp: any) => {
    const err = resp?.data?.message || resp?.response?.data?.message || null;
    if (err) {
      return err;
    }
  };
  const resetError = () => {
    dispatch(setError(null));
  };
  const fetchAllTenants = () => {
    return new Promise((resolve, reject) => {
      TenantService.fetchAll()
        .then(resp => {
          if (resp?.data?.success) {
            const data = getDataFromResponse(resp);
            dispatch(setAllTenants(data));
            resolve(data);
          } else {
            const errMsg = getErrorMessage(resp);
            dispatch(setError(errMsg));
            reject(errMsg);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };
  const createTenant = (data: TenantWithLogoFile) => {
    return new Promise((resolve, reject) => {
      const formData = objectToFormData({
        ...data,
        redirect_url: `${process.env.REACT_APP_PASSWORD_LINK || ''}`
      });

      TenantService.createTenant(formData)
        .then(resp => {
          if (resp?.data?.success) {
            const data = getDataFromResponse(resp);
            resolve(data);
          } else {
            const errMsg = getErrorMessage(resp);
            dispatch(setError(errMsg));
            reject(errMsg);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };

  const updateTenant = (data: TenantWithLogoFile, id: string) => {
    return new Promise((resolve, reject) => {
      const formData = objectToFormData({ ...data, _method: 'PUT' });

      TenantService.updateTenant(formData, id)
        .then(resp => {
          if (resp?.data?.success) {
            const data = getDataFromResponse(resp);
            const updatedList = updateCurrentTenantList({
              tenants: tenants,
              updatedTenantDetails: data.tenant_details
            });
            dispatch(setAllTenants(updatedList));
            resolve(data);
          } else {
            const errMsg = getErrorMessage(resp);
            dispatch(setError(errMsg));
            reject(errMsg);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };
  const resetPassword = (tenantEmail: string) => {
    const reset_url = process.env.REACT_APP_PASSWORD_LINK || '';
    return new Promise((resolve, reject) => {
      TenantService.resetPassword({ to: tenantEmail, reset_url })
        .then(response => {
          if (response?.data?.success) {
            resolve(true);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };
  const deleteTenant = (id: string) => {
    return new Promise((resolve, reject) => {
      if (!id) reject();
      TenantService.deleteTenant(id)
        .then(response => {
          //eslint-disable-next-line
          console.log('returnnewPromise ~ response:', response);

          if (response?.data?.success) {
            resolve(true);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };
  return {
    tenants,
    error,
    fetchAllTenants,
    resetError,
    createTenant,
    updateTenant,
    resetPassword,
    deleteTenant
  };
};

export default useTenant;
