import { RouteObject } from 'react-router-dom';
import SignIn from 'pages/authentication/simple/SignIn';
import SignOut from 'pages/authentication/simple/SignOut';
import ForgotPassword from 'pages/authentication/simple/ForgotPassword';
import ResetPassword from 'pages/authentication/simple/ResetPassword';

import AuthLayout from 'layouts/AuthLayout';
import GuestGuard from 'utils/route-guard/GuestGuard';

const AuthRoutes: RouteObject = {
  path: '/auth/',
  element: <AuthLayout />,
  children: [
    {
      path: 'sign-in',
      element: (
        <GuestGuard>
          <SignIn />
        </GuestGuard>
      )
    },

    {
      path: 'sign-out',
      element: <SignOut />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'reset-password',
      element: <ResetPassword />
    }
  ]
};

export default AuthRoutes;
