/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';

import { Button, Form, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CreateRolesData, Role } from 'types/roles';
import useRoles from 'hooks/useRoles';
import ScopesSelect from 'components/multi-select/ScopesSelect';
import useScopes from 'hooks/useScopes';
interface AddTenantPanelProps {
  open: boolean;
  onClose?: Dispatch<SetStateAction<boolean>>;
  defaultTenant?: Role;
}
const initialTenantValues: Role = {
  name: '',
  tenant_id: null,
  display_name: '',
  created_at: '',
  updated_at: '',
  permissions: []
};
const AddRolePanel = (props: AddTenantPanelProps) => {
  const { open, onClose, defaultTenant } = props;
  const { createRoles, error, resetError, fetchAllRoles } = useRoles();
  const { scopesOptions } = useScopes();
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scopesList, setScopesList] = useState<number[]>([]);
  const [selectedTenant, setSelectedTenant] =
    useState<Role>(initialTenantValues);

  useEffect(() => {
    const tenant = defaultTenant || initialTenantValues;
    setSelectedTenant(tenant);
  }, [defaultTenant]);

  const handleClose = () => {
    if (onClose) onClose(!open);
    setIsSubmitting(false);
    setValidated(false);
    resetError();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsSubmitting(true);
    setValidated(true);

    const form = event.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      name: { value: string };
      display_name: { value: string };
      // email: { value: string };
    };
    const roleData: CreateRolesData = {
      roles: [
        {
          name: formElements.name.value,
          display_name: formElements.display_name.value,
          scopes: scopesList
        }
      ]
    };
    // const
    if (form.checkValidity()) {
      createRoles(roleData)
        .then(() => {
          fetchAllRoles();
          setIsSubmitting(false);
          handleClose();
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(false);
    }
  };
  const handleResetToDefault = () => {
    setSelectedTenant(defaultTenant ? defaultTenant : initialTenantValues);
    setIsSubmitting(false);
    setValidated(false);
    setScopesList([]);
    resetError();
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    setSelectedTenant({
      ...selectedTenant,
      [key]: e.target.value
    });
  };
  const handleScopesSelect = (values: number[]) => {
    setScopesList(values);
  };
  return (
    <Offcanvas
      className="settings-panel border-0"
      show={open}
      onHide={handleClose}
      placement="end"
    >
      <Offcanvas.Header className="align-items-start border-bottom border-translucent flex-column">
        <div className="pt-1 w-100 mb-6 d-flex justify-content-between align-items-start">
          <div>
            <h5 className="mb-2 me-2 lh-sm">
              {/* <FontAwesomeIcon icon={faPalette} className="me-2 fs-8" /> */}
              {defaultTenant ? 'Edit ' : 'Add '}
              Role
            </h5>
            <p className="mb-0 fs-9">
              Please fill up the form and submit to{' '}
              {defaultTenant ? 'edit the selected role.' : 'create a new role.'}
            </p>
          </div>
          <button className="btn p-1 fw-bolder" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="fs-8" />
          </button>
        </div>
        <Button
          variant="phoenix-secondary"
          className="w-100"
          onClick={handleResetToDefault}
        >
          <FontAwesomeIcon icon={faArrowsRotate} className="me-2 fs-10" />
          Reset to default
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-card scrollbar">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              required
              value={selectedTenant.name}
              onChange={e => handleInputChange(e as any, 'name')}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name for the role.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="display_name">
            <Form.Label>Display name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Display Name"
              required
              value={selectedTenant.display_name}
              onChange={e => handleInputChange(e as any, 'display_name')}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a display name for the role.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="permissions">
            <ScopesSelect
              title={'Permissions'}
              options={scopesOptions}
              onSelectChange={handleScopesSelect}
            />
          </Form.Group>

          {error && <div className="mb-3 small text-danger">{error}</div>}
          <Button
            disabled={isSubmitting}
            variant="primary"
            type="submit"
            className="d-flex align-items-center"
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm me-1"></span>
            )}
            Submit
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

AddRolePanel.propTypes = {};

export default AddRolePanel;
