import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth';
import tenantReducer from './tenantSlice';
import rolesReducer from './rolesSlice';
import scopesReducer from './scopesSlice';
import resourceReducer from './resourcesSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  tenant: tenantReducer,
  roles: rolesReducer,
  scopes: scopesReducer,
  resources: resourceReducer
});

export default rootReducer;
