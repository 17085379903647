import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';

import { lazy, Suspense } from 'react';
import PhoenixLoader from 'components/common/PhoenixLoader';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Tenants from 'pages/Tenants';
import RolesPage from 'pages/RolesPage';
import ResourcesPage from 'pages/ResourcesPage';

const Dashboard = lazy(() => import('pages/dashboard'));

const MainRoutes: RouteObject = {
  path: '/',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      index: true,
      path: '/dashboard',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <Dashboard />
        </Suspense>
      )
    },
    {
      index: true,
      path: '/',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <Tenants />
        </Suspense>
      )
    },
    {
      path: '/pages',
      children: [
        {
          path: 'tenant-list',
          index: true,
          element: (
            <Suspense fallback={<PhoenixLoader />}>
              <Tenants />
            </Suspense>
          )
        },
        {
          path: 'roles-list',
          index: true,
          element: (
            <Suspense fallback={<PhoenixLoader />}>
              <RolesPage />
            </Suspense>
          )
        },
        {
          path: 'resources-list',
          index: true,
          element: (
            <Suspense fallback={<PhoenixLoader />}>
              <ResourcesPage />
            </Suspense>
          )
        }
      ]
    }
  ]
};

export default MainRoutes;
