/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactSelect from 'components/base/ReactSelect';
import { Form } from 'react-bootstrap';
import { MultipleSelectOption } from 'types/common';

interface MultiOptionsSelectProps {
  title?: string;
  options: MultipleSelectOption[];
  onSelectChange: (values: MultipleSelectOption[]) => void;
  value: MultipleSelectOption[];
}
const MultiOptionsSelect = ({
  title,
  options,
  onSelectChange,
  value
}: MultiOptionsSelectProps) => {
  const handleChange = (e: any) => {
    onSelectChange(e);
  };
  return (
    <div>
      {title ? <Form.Label>{title}</Form.Label> : <></>}
      <ReactSelect
        options={options}
        isMulti
        placeholder="Select Resources..."
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};

MultiOptionsSelect.propTypes = {};

export default MultiOptionsSelect;
