import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { AlertType } from '../../types/alert';

const AlertMessage = ({ type, message }: AlertType) => {
  const alertType = type?.toLowerCase();
  const icon =
    alertType === 'success'
      ? faCheckCircle
      : alertType === 'warning'
      ? faTriangleExclamation
      : faTriangleExclamation;
  return (
    <Alert variant={`subtle-${alertType}`}>
      <span className={`text-${alertType}  font-medium`}>
        <FontAwesomeIcon icon={icon} className={`me-2 text-${alertType}`} />
        {message}
      </span>
    </Alert>
  );
};

export default AlertMessage;
