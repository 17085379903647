import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TenantsTable, {
  tenantsTablecolumns
} from 'components/tables/TenantsTable';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddTenantPanel from 'components/side-panels/AddTenantPanel';
import useTenant from 'hooks/useTenant';
import useAuth from 'hooks/useAuth';
import { TenantWithLogoUrl } from 'types/tenant';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { modalType } from 'constantStrings';
import AutoHideToast from 'components/base/AutoHideToast';

const Tenants = () => {
  const {
    tenants,
    fetchAllTenants,
    error,
    resetError,
    resetPassword,
    deleteTenant
  } = useTenant();
  const { logout } = useAuth();
  const [selectedTenant, setSelectedTenant] = useState<
    TenantWithLogoUrl | undefined
  >(undefined);

  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [openAddTenantPanel, setOpenAddTenantPanel] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [toastProps, setToastProps] = useState<{
    show: boolean;
    message: string;
  }>({ show: false, message: '' });

  useEffect(() => {
    fetchAllTenants().catch(() => {});
  }, []);

  useEffect(() => {
    if (error === 'Unauthenticated') {
      logout();
      resetError();
    }
  }, [error]);

  const table = useAdvanceTable({
    data: tenants,
    columns: tenantsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    onEdit: data => {
      //resetPasswordConfirmed sud be false
      setSelectedTenant(data);
      setOpenAddTenantPanel(true);
    },
    onDelete: data => {
      setSelectedTenant(data);
      setDeleteConfirmation(true);

      // handleOnDelete(data);
    }
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleConfirmation = () => {
    if (selectedTenant)
      resetPassword(selectedTenant?.email)
        .then(success => {
          if (success) {
            //TODO: show toast
            setToastProps({
              show: true,
              message: 'Password has been reset successfully'
            });
            updateList();
          }
        })
        .catch(() => {});
  };
  const handleDeleteTenant = () => {
    if (selectedTenant?.id)
      deleteTenant(selectedTenant.id)
        .then(success => {
          if (success) {
            //TODO: show toast
            setToastProps({
              show: true,
              message: 'Tenant deleted successfully'
            });
            updateList();
          }
        })
        .catch(() => {});
  };
  const updateList = () => {
    fetchAllTenants().catch(() => {});
  };
  return (
    <div>
      {/* <PageBreadcrumb items={tenantsBreadcrumbItems} /> */}
      <div className="mb-9">
        <h2 className="mb-5">Tenants</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search tenants"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <Button
                  onClick={() => {
                    setSelectedTenant(undefined);
                    setOpenAddTenantPanel(!openAddTenantPanel);
                  }}
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Tenant
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <TenantsTable />
          </div>
          <AddTenantPanel
            open={openAddTenantPanel}
            onClose={setOpenAddTenantPanel}
            defaultTenant={selectedTenant}
            showResetConfimationModal={setConfirmationModalShow}
          />
        </AdvanceTableProvider>
        <ConfirmationModal
          modalShow={confirmationModalShow}
          setModalShow={setConfirmationModalShow}
          handleConfirmation={handleConfirmation}
          modalHeading="Reset password"
          modalBody="Are you sure you want to reset password of the tenant?"
        />
        <ConfirmationModal
          type={modalType.DANGER}
          modalShow={deleteConfirmation}
          setModalShow={setDeleteConfirmation}
          handleConfirmation={handleDeleteTenant}
          modalHeading="Delete Tenant"
          modalBody="Are you sure you want to delete the tanant?"
        />
      </div>
      <AutoHideToast
        show={toastProps.show}
        setShow={val => {
          setToastProps({ ...toastProps, show: val });
        }}
        toastBody={toastProps.message}
      />
    </div>
  );
};

export default Tenants;
