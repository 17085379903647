/* eslint-disable @typescript-eslint/no-explicit-any */
import IndeterminateCheckbox from 'components/base/IndeterminateCheckbox';
import { PropsWithChildren } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  InitialTableState
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faEdit, faEye, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

interface UseAdvanceTableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  selection?: boolean;
  sortable?: boolean;
  pagination?: boolean;
  pageSize?: number;
  selectionColumnWidth?: number | string;
  initialState?: InitialTableState;
  onView?: (data: any) => void;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
}

const selectionColumn = {
  id: 'select',
  accessorKey: '',
  header: ({ table }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler()
      }}
    />
  ),
  cell: ({ row }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler()
      }}
    />
  ),
  meta: {
    headerProps: { style: { width: '30px' } }
  }
};

const useAdvanceTable = <T,>({
  columns,
  data,
  selection,
  sortable,
  pagination,
  pageSize,
  initialState,
  onView,
  onEdit,
  onDelete
}: PropsWithChildren<UseAdvanceTableProps<T>>) => {
  const state = {
    pagination: pagination
      ? { pageSize: pagination ? pageSize : data.length }
      : undefined,
    ...initialState
  };
  const actionColumn = {
    id: 'action',
    cell: (original: any) => {
      const { row } = original;
      return (
        <>
          <span className="d-flex justify-content-end">
            {onEdit && (
              <Button
                variant="subtle-info"
                className="btn-circle"
                onClick={() => {
                  onEdit?.(row?.original || null);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}

            {onView && (
              <Button
                variant="subtle-primary"
                onClick={() => {
                  onView?.(row?.original || null);
                }}
                className="btn-circle ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            )}
            {onDelete && (
              <Button
                variant="subtle-danger"
                className="btn-circle ml-2"
                style={{ marginLeft: '4px' }}
                onClick={() => {
                  onDelete?.(row?.original || null);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </span>
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  };
  const table = useReactTable<T>({
    data,
    columns: selection
      ? [selectionColumn, ...columns, ...[actionColumn]]
      : [...columns, ...[actionColumn]],
    enableSorting: sortable,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: state
  });

  return table;
};

export default useAdvanceTable;
