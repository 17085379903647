/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDataFromResponse } from 'helpers/objectUtils';
import ResourcesService from 'services/ResourcesService';
import { useDispatch, useSelector } from 'store';
import { setAllResources, setError } from 'store/reducers/resourcesSlice';

const useResources = () => {
  const dispatch = useDispatch();
  const { resources, error, resourcesOptions } = useSelector(
    state => state.resources
  );

  const getErrorMessage = (resp: any) => {
    const err = resp?.data?.message || resp?.response?.data?.message || null;
    if (err) {
      return err;
    }
  };
  const resetError = () => {
    dispatch(setError(null));
  };
  const fetchAllResources = () => {
    return new Promise((resolve, reject) => {
      ResourcesService.fetchAllResources()
        .then(resp => {
          if (resp?.data?.success) {
            const data = getDataFromResponse(resp);
            dispatch(setAllResources(data));
            resolve(data);
          } else {
            const errMsg = getErrorMessage(resp);
            dispatch(setError(errMsg));
            reject(errMsg);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };

  return {
    resources,
    resourcesOptions,
    error,
    fetchAllResources,
    resetError
  };
};

export default useResources;
