import SignInForm from 'components/modules/auth/SignInForm';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

const SignIn = () => {
  document.title = process.env.REACT_APP_NAME + ' | Sign In';
  return (
    <AuthSimpleLayout>
      <SignInForm />
    </AuthSimpleLayout>
  );
};

export default SignIn;
