import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { formatDate } from 'helpers/stringUtils';
import { Role } from 'types/roles';

export const rolesTablecolumns: ColumnDef<Role>[] = [
  {
    accessorKey: 'id',
    header: 'Id',
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: '50px', maxWidth: '70px' },
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start text-body-tertiary'
      }
    }
  },
  {
    accessorKey: 'name',
    header: 'Role',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to="#!" className=" d-flex align-items-center text-body">
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
        </Link>
      );
    }
    // meta: {
    //   headerProps: {
    //     style: { width: '15%', minWidth: '200px' },
    //     className: 'px-1'
    //   },
    //   cellProps: { className: 'align-middle white-space-nowrap py-2' }
    // }
  },

  {
    accessorKey: 'display_name',
    header: 'Display Name',
    cell: ({ row: { original } }) => {
      const { display_name } = original;
      return (
        <Link to={`#!`} className="fw-bold text-body-emphasis">
          {display_name}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'pe-3'
      }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'Created at',
    meta: {
      headerProps: {
        style: { width: '40%', minWidth: '200px' },
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center text-body-tertiary'
      }
    },
    cell: ({ row: { original } }) => {
      const { created_at } = original;
      return <p>{formatDate(created_at)}</p>;
    }
  }
];

const RolesTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RolesTable;
