import { createSlice } from '@reduxjs/toolkit';
import { Role } from 'types/roles';

interface RolesState {
  roles: Role[];
  error: string | null;
}
const initialState: RolesState = {
  roles: [],
  error: null
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setAllRoles(state, action) {
      state.roles = action.payload || [];
      state.error = null;
    },
    setError(state, action) {
      state.error = action.payload || null;
    }
  }
});

export const { setAllRoles, setError } = rolesSlice.actions;
export default rolesSlice.reducer;
