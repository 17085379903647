export const formatDate = (dateString: string) => {
  if (!dateString) return '';
  const thisTime = new Date(dateString);
  const option: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', option).format(
    thisTime
  );
  return formattedDate;
};

export const getLogoUrl = (filename: string) => {
  return `${process.env.REACT_APP_STORAGE_ENDPOINT}/${filename}`;
};
