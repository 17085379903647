import { createSlice } from '@reduxjs/toolkit';
import { TenantWithLogoUrl } from 'types/tenant';

interface TenantState {
  tenants: TenantWithLogoUrl[];
  error: string | null;
}

const initialState: TenantState = {
  tenants: [],
  error: null
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setAllTenants(state, action) {
      state.tenants = action.payload || [];
      state.error = null;
    },
    setError(state, action) {
      state.error = action.payload || null;
    }
  }
});

export const { setAllTenants, setError } = tenantSlice.actions;
export default tenantSlice.reducer;
