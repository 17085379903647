import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'dashboard',
        path: '/dashboard',
        pathName: 'default-dashboard',
        active: true,
        icon: 'home'
      }
    ]
  },
  {
    label: 'pages',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Tenant-List',
        path: '/pages/tenant-list',
        pathName: 'starter',
        active: true,
        icon: 'users'
      },
      {
        name: 'Resources-List',
        path: '/pages/resources-list',
        pathName: 'starter',
        active: true,
        icon: 'book'
      }
    ]
  }
];
