import axios from 'axios';
const tenantEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/auth/tenants`;
const tenantResetPassword: string = `${process.env.REACT_APP_ENDPOINT}/auth/request-password-reset`;

const fetchAll = () => {
  return axios.get(`${tenantEndpoint}`);
};
const createTenant = (formData: FormData) => {
  return axios.post(`${tenantEndpoint}`, formData);
};
const updateTenant = (formData: FormData, id: string) => {
  return axios.post(`${tenantEndpoint}/${id}`, formData);
};

const deleteTenant = (id: string) => {
  return axios.delete(`${tenantEndpoint}/${id}`);
};
const resetPassword = ({
  to,
  reset_url
}: {
  to: string;
  reset_url: string;
}) => {
  return axios.post(`${tenantResetPassword}`, { to, reset_url });
};

const TenantService = {
  fetchAll,
  createTenant,
  updateTenant,
  resetPassword,
  deleteTenant
};

export default TenantService;
