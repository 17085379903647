import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getMultipleOptionDropdownList } from 'helpers/arrayUtils';
import { Scope, ScopeOption } from 'types/scopes';

interface ScopeState {
  scopes: Scope[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  scopesOptions: ScopeOption[];
}

const initialState: ScopeState = {
  scopes: [],
  status: 'idle',
  error: null,
  scopesOptions: []
};

export const fetchScopes = createAsyncThunk('auth/scopes', async () => {
  const { data } = await axios(`${process.env.REACT_APP_ENDPOINT}/auth/scopes`);
  const scopeslist = data?.rows;
  return scopeslist as Scope[];
});

const scopesSlice = createSlice({
  name: 'scopes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchScopes.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchScopes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.scopes = action.payload;
        state.scopesOptions = getMultipleOptionDropdownList(action.payload);
      })
      .addCase(fetchScopes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch products';
      });
  }
});

export default scopesSlice.reducer;
