import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { AlertType } from 'types/alert';
import { ForgotPassword } from 'types/auth';
import { forgotEmailValidationSchema } from 'validations/auth';
import AlertMessage from 'components/common/AlertMessage';
import { Status } from 'constantStrings';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  const { forgotPassword } = useAuth();
  const [alert, setAlert] = useState<AlertType | null>(null);
  const initialValues: { to: string } = {
    to: ''
  };
  const [loader, setLoader] = useState<{ form?: boolean }>({
    form: false
  });

  const onSubmit = async (
    values: { to: string },
    { resetForm }: FormikHelpers<{ to: string }>
  ) => {
    const data: ForgotPassword = {
      to: values.to,
      reset_url: process.env.REACT_APP_PASSWORD_LINK || ''
    };
    setLoader({ form: true });
    forgotPassword(data)
      .then(() => {
        setLoader({ form: false });
        resetForm();
        setAlert({
          type: Status.SUCCESS,
          message: 'Success to send reset password link to a mail.'
        });
      })
      .catch(e => {
        if (e?.response?.status == '404') {
          setAlert({ type: Status.DANGER, message: 'User not found!' });
        } else {
          setAlert({ type: Status.DANGER, message: 'Something Went Wrong!' });
        }
        setLoader({ form: false });
      });
  };

  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-body-highlight">Forgot your password?</h4>
        <p className="text-body-tertiary mb-5">
          Enter your email below and we will send <br className="d-sm-none" />
          you a reset link
        </p>
        {alert && <AlertMessage type={alert.type} message={alert.message} />}

        <Formik
          initialValues={initialValues}
          validationSchema={forgotEmailValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="d-flex align-items-center mb-5"
            >
              <Form.Control
                type="email"
                id="email"
                name="to"
                className={`flex-1 form-control form-icon-input ${
                  touched.to && errors.to ? 'is-invalid' : ''
                }`}
                placeholder="E-mail"
                value={values.to}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <Button
                type="submit"
                variant="primary"
                className="ms-2"
                endIcon={
                  <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                }
                disabled={isSubmitting || loader.form}
              >
                Send email
              </Button>
            </Form>
          )}
        </Formik>
        <Link to="/auth/sign-in" className="fs-9 fw-bold">
          Go back to login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
