import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useAuth from 'hooks/useAuth';
import useRoles from 'hooks/useRoles';
import RolesTable, { rolesTablecolumns } from 'components/tables/RolesTable';
import AddRolePanel from 'components/side-panels/AddRolePanel';
import { Role } from 'types/roles';
import useScopes from 'hooks/useScopes';

const RolesPage = () => {
  const { roles, fetchAllRoles, error, resetError } = useRoles();
  const { scopes, fetchAllScopes } = useScopes();

  const { logout } = useAuth();
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);

  useEffect(() => {
    fetchAllRoles().catch(() => {});
  }, []);
  useEffect(() => {
    if (!scopes?.length) {
      fetchAllScopes();
    }
  }, [scopes]);

  useEffect(() => {
    if (error === 'Unauthenticated') {
      logout();
      resetError();
    }
  }, [error]);

  const table = useAdvanceTable({
    data: roles,
    columns: rolesTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    onEdit: data => {
      setSelectedRole(data);
      setOpenPanel(true);
    },
    onDelete: data => {
      setSelectedRole(data);
      setOpenPanel(true);
      // handleOnDelete(data);
    }
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [openPanel, setOpenPanel] = useState(false);
  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Roles</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search roles"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    setSelectedRole(undefined);
                    setOpenPanel(!openPanel);
                  }}
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Roles
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <RolesTable />
          </div>
          <AddRolePanel
            open={openPanel}
            onClose={setOpenPanel}
            defaultTenant={selectedRole}
          />
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default RolesPage;
