import {
  faEye,
  faEyeSlash,
  faKey,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoginFormData } from 'types/auth';
import { Formik } from 'formik';
import { loginValidationSchema } from 'validations/auth';
import { AlertType } from 'types/alert';
import { Status } from 'constantStrings';
import AlertMessage from 'components/common/AlertMessage';

const SignInForm = () => {
  const { login } = useAuth();

  const initialValues: LoginFormData = {
    email: process.env.REACT_APP_DEFAULT_LOGIN_EMAIL || '',
    password: process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD || ''
  };

  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [loader, setLoader] = useState<{ form?: boolean }>({
    form: false
  });
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (values: LoginFormData) => {
    setLoader({ form: true });
    login(values)
      .then(() => {
        setLoader({ form: false });
      })
      .catch(e => {
        if (e?.response?.status == '401') {
          setAlert({
            type: Status.DANGER,
            message: 'Invalid Email or Password !'
          });
        } else {
          setAlert({ type: Status.DANGER, message: 'Something Went Wrong !' });
        }
        setLoader({ form: false });
      });
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign In</h3>
        <p className="text-body-tertiary">Get access to super admin account</p>
      </div>
      {alert && <AlertMessage type={alert.type} message={alert.message} />}
      <Formik
        initialValues={initialValues}
        validationSchema={loginValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">Email address</Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="email"
                  type="email"
                  className={`form-control form-icon-input ${
                    touched.email && errors.email ? 'is-invalid' : ''
                  }`}
                  placeholder="E-mail"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="password">Password</Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control form-icon-input ${
                    touched.password && errors.password ? 'is-invalid' : ''
                  }`}
                  placeholder="Password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
                <FontAwesomeIcon
                  icon={faKey}
                  className="text-body fs-9 form-icon"
                />
                <FontAwesomeIcon
                  onClick={handleClickShowPassword}
                  style={{ left: '90%' }}
                  icon={showPassword ? faEye : faEyeSlash}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Row className="flex-between-center mb-7">
              <Col xs="auto">
                <Link to={`/auth/forgot-password`} className="fs-9 fw-semibold">
                  Forgot Password?
                </Link>
              </Col>
            </Row>
            <Button
              type="submit"
              variant="primary"
              className="w-100 mb-3"
              disabled={isSubmitting || loader.form}
            >
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignInForm;
