import { createSlice } from '@reduxjs/toolkit';
import { getMultipleOptionDropdownList } from 'helpers/arrayUtils';
import { MultipleSelectOption } from 'types/common';
import { Resource } from 'types/resources';

interface ResourcesState {
  resources: Resource[];
  resourcesOptions: MultipleSelectOption[];
  error: string | null;
}
const initialState: ResourcesState = {
  resources: [],
  resourcesOptions: [],
  error: null
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setAllResources(state, action) {
      state.resources = action.payload || [];
      state.resourcesOptions = getMultipleOptionDropdownList(action.payload);
      state.error = null;
    },
    setError(state, action) {
      state.error = action.payload || null;
    }
  }
});

export const { setAllResources, setError } = resourcesSlice.actions;
export default resourcesSlice.reducer;
