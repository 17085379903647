/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

axios.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);
const post = (url: string = '', data: any = '', config: any = {}) => {
  return axios.post(url, data, config);
};
const put = (url: string = '', data: any = '', config = {}) => {
  return axios.put(url, data, config);
};
const get = (url: string, config = {}) => {
  return axios(url, config);
};
const remove = (url: string) => {
  return axios.delete(url);
};
const HttpClient = {
  post,
  get,
  put,
  remove
};

export default HttpClient;
