/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDataFromResponse } from 'helpers/objectUtils';
import RolesService from 'services/RolesService';
import { useDispatch, useSelector } from 'store';
import { setAllRoles, setError } from 'store/reducers/rolesSlice';
import { CreateRolesData } from 'types/roles';

const useRoles = () => {
  const dispatch = useDispatch();
  const { roles, error } = useSelector(state => state.roles);

  const getErrorMessage = (resp: any) => {
    const err = resp?.data?.message || resp?.response?.data?.message || null;
    if (err) {
      return err;
    }
  };
  const resetError = () => {
    dispatch(setError(null));
  };
  const fetchAllRoles = () => {
    return new Promise((resolve, reject) => {
      RolesService.fetchAllRoles()
        .then(resp => {
          if (resp?.data?.success) {
            const data = resp?.data?.rows || [];
            dispatch(setAllRoles(data));
            resolve(data);
          } else {
            const errMsg = getErrorMessage(resp);
            dispatch(setError(errMsg));
            reject(errMsg);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };
  const createRoles = (data: CreateRolesData) => {
    return new Promise((resolve, reject) => {
      RolesService.createRoles(data)
        .then(resp => {
          if (resp?.data?.success) {
            const data = getDataFromResponse(resp);
            resolve(data);
          } else {
            const errMsg = getErrorMessage(resp);
            dispatch(setError(errMsg));
            reject(errMsg);
          }
        })
        .catch(err => {
          const errMsg = getErrorMessage(err);
          dispatch(setError(errMsg));
          reject(errMsg);
        });
    });
  };
  return {
    roles,
    error,
    fetchAllRoles,
    resetError,
    createRoles
  };
};

export default useRoles;
