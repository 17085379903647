import { Button, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface ToastProps {
  show: boolean;
  setShow: (val: boolean) => void;
  showHeader?: boolean;
  heading?: string;
  toastBody: string;
}
const AutoHideToast = (props: ToastProps) => {
  const { show, setShow, showHeader = false, heading, toastBody = '' } = props;
  return (
    <>
      <div className="position-fixed bottom-0 end-0 p-3 z-5">
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          {showHeader && heading ? (
            <Toast.Header closeButton={false}>
              <strong className="me-auto">{heading}</strong>
              <Button className="ms-2 p-0 " onClick={() => setShow(false)}>
                <FontAwesomeIcon icon={faClose} className="fs-7" />
              </Button>
            </Toast.Header>
          ) : null}

          <Toast.Body>
            <div className="d-flex gap-1 flex-between-center">
              <div>{toastBody}</div>
              {!showHeader ? (
                <span onClick={() => setShow(false)}>
                  <FontAwesomeIcon icon={faClose} />
                </span>
              ) : (
                <></>
              )}
            </div>
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};

AutoHideToast.propTypes = {};

export default AutoHideToast;
